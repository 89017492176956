import './AboutNav.css';

function AboutNav() {
	return (
		<nav>
			<div>
				<img alt="ScrAlling Logo" src="logo.svg" />
				<h1>ScrAlling</h1>
			</div>
			<h2>Full Page Scrolling Guideline</h2>
		</nav>
	);
}

export default AboutNav;
