import './Aboutfooter.css';

function AboutFooter() {
	return (
		<footer>
			ScrAlling by <a href="https://fausrguez.com">@fausrguez</a>
		</footer>
	);
}

export default AboutFooter;
