window.resetSections = () => {
	window.sections[window.currentSection].classList.remove('active');
	window.sections.forEach((section) => {
		window.setActiveSubSection(section, 0);
	});
	window.setActiveSection(0);
};

window.setActiveSection = (section) => {
	window.currentSection = section;
	window.sections[section].classList.add('active');
	window.setActiveSubSection(window.sections[section]);
};

window.nextSection = () => {
	if (window.currentSection + 1 < window.sections.length) {
		window.sections[window.currentSection].classList.remove('active');
		window.setActiveSection(++window.currentSection);
	}
};

window.prevSection = () => {
	if (window.currentSection > 0) {
		window.sections[window.currentSection].classList.remove('active');
		window.setActiveSection(--window.currentSection);
	}
};

window.setActiveSubSection = (section, subSection) => {
	if (section.classList.contains('complex-section')) {
		section.subSection =
			subSection === 0 ? subSection : subSection || section.subSection || 0;
		section.querySelector('.active')?.classList.remove('active');
		section
			.querySelectorAll('section')
			[section.subSection].classList.add('active');
	}
};

window.nextSubSection = () => {
	const section = window.sections[window.currentSection];
	if (section.classList.contains('complex-section')) {
		const subSections = section.querySelectorAll('section');
		if (section.subSection + 1 < subSections.length) {
			window.setActiveSubSection(section, ++section.subSection);
		}
	}
};

window.prevSubSection = () => {
	const section = window.sections[window.currentSection];
	if (section.classList.contains('complex-section') && section.subSection > 0) {
		window.setActiveSubSection(section, --section.subSection);
	}
};
