import './UiCaption.css';

import { isMobile } from 'react-device-detect';

function UiCaption(props) {
	const deviceAction = isMobile ? (
		<span>Tap, use the arrows or swipe :)</span>
	) : (
		<span>
			Click on the arrows, use your keyboard or your mouse to navigate
		</span>
	);
	return (
		<div className="ui-caption">
			{deviceAction}
			<div className="commands">
				<button onClick={window.prevSection}>
					<img alt="arrow-up" src="up.svg"></img>
				</button>
				<button onClick={window.nextSection}>
					<img alt="arrow-down" src="down.svg"></img>
				</button>
			</div>
		</div>
	);
}

export default UiCaption;
