let pageWidth = window.innerWidth || document.body.clientWidth;
let treshold = Math.max(1, Math.floor(0.01 * pageWidth));
let touchstartY = 0;
let touchendY = 0;

function handleGesture() {
	const y = touchendY - touchstartY;
	if (Math.abs(y) > treshold) {
		y < 0 ? window.nextSection() : window.prevSection();
	}
}

function handleTouch() {
	window.addEventListener(
		'touchstart',
		(event) => {
			touchstartY = event.changedTouches[0].screenY;
		},
		false,
	);

	window.addEventListener(
		'touchend',
		(event) => {
			touchendY = event.changedTouches[0].screenY;
			handleGesture();
		},
		false,
	);
}

export default handleTouch;
