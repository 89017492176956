function handleKeyboard() {
	window.addEventListener('keyup', (key) => {
		switch (key.code) {
			case 'ArrowDown':
			case 'Space':
				window.nextSection();
				break;
			case 'ArrowUp':
				window.prevSection();
				break;
			case 'ArrowRight':
				window.nextSubSection();
				break;
			case 'ArrowLeft':
				window.prevSubSection();
				break;
			case 'Escape':
				window.resetSections();
				break;
			default:
				return;
		}
	});
}

export default handleKeyboard;
