import ComplexSectionControls from './components/ComplexSectionControls';

function ComplexSection(props) {
	return (
		<section className="complex-section">
			{props.children}
			<ComplexSectionControls />
		</section>
	);
}

export default ComplexSection;
