import './Section.css';

function capitalize(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

function Section(props) {
	return (
		<section className={props.bgcolor}>{capitalize(props.content)}</section>
	);
}

export default Section;
