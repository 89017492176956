function scrollToCurrentSection() {
	const isScrollingDown = (-window.event.detail || window.event.wheelDelta) < 0;
	isScrollingDown ? window.nextSection() : window.prevSection();
}

function handleScrolling() {
	window.sections = document.querySelectorAll('main > section');
	window.currentSection = 0;
	window.setActiveSection(window.currentSection);

	// Mozilla
	if (window.addEventListener) {
		document.addEventListener('DOMMouseScroll', scrollToCurrentSection, false);
	}
	//for the other browsers
	document.onmousewheel = scrollToCurrentSection;
}

export default handleScrolling;
