import './App.css';

import Section from './components/section/Section';
import ComplexSection from './components/complex-section/ComplexSection';
import UiCaption from './components/ui-caption/UiCaption';
import AboutNav from './components/about-nav/AboutNav';
import AboutFooter from './components/about-footer/AboutFooter';

import './section-manager';
import handleScrolling from './scrolling-support';
import handleTouch from './touchscreen-support';
import handleKeyboard from './keyboard-support';

window.onload = () => {
	handleScrolling();
	handleTouch();
	handleKeyboard();
};

function App() {
	return (
		<main className="App">
			<AboutNav />
			<Section content="section 1" bgcolor="red" />
			<Section content="section 2" bgcolor="green" />
			<Section content="section 3" bgcolor="yellow" />
			<Section content="section 4" bgcolor="blue" />
			<ComplexSection>
				<Section content="section 5" bgcolor="pink" />
				<Section content="section 6" bgcolor="purple" />
			</ComplexSection>
			<Section content="section 7" bgcolor="orange" />
			<ComplexSection>
				<Section content="section 8" bgcolor="light-blue" />
				<Section content="section 9" bgcolor="gray" />
				<Section content="section 10" />
			</ComplexSection>
			<UiCaption />
			<AboutFooter />
		</main>
	);
}

export default App;
