import './ComplexSectionControls.css';

function ComplexSectionControls() {
	return (
		<div className="complex-section-controls">
			<div className="controls">
				<button onClick={window.prevSubSection}>
					<img alt="arrow-left" src="left.svg"></img>
				</button>
				<button onClick={window.nextSubSection}>
					<img alt="arrow-right" src="right.svg"></img>
				</button>
			</div>
		</div>
	);
}

export default ComplexSectionControls;
